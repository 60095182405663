<template>
  <div class="new-website container-small">
    <FullScreenLoader v-if="showLoader" />
    <h4>Create A New Website</h4>
    <div class="site-name">
      <div class="input">
        <label style="font-size: 20px; font-weight: normal;" for="site-name"
          >Enter your site name</label
        >
        <input
          ref="siteName"
          style="width: 400px;"
          type="text"
          id="site-name"
          v-model="site.name"
          placeholder="E.g. My Portfolio"
        />
      </div>
    </div>
    <div class="packages-container">
      <p class="select-plan">Select a plan</p>
      <div class="bill-type">
        <p
          :class="{ 'bill-active': site.billType === 'm' }"
          @click="site.billType = 'm'"
          class="bill-type-text"
        >
          Bill Monthly
        </p>
        <p
          :class="{ 'bill-active': site.billType === 'y' }"
          @click="site.billType = 'y'"
          class="bill-type-text"
        >
          Bill Yearly
        </p>
      </div>
      <div class="packages">
        <Package
          v-for="pack in packages"
          :key="pack._id"
          :pack="pack"
          :billType="site.billType"
          :active="site.package === pack._id"
          @click.native="site.package = pack._id"
        />
      </div>
    </div>
    <div class="packages-container">
      <p class="select-plan">Select a payment method</p>
      <div class="payment-methods">
        <div
          class="payment-method"
          @click="site.paymentMethod = 'bkash'"
          :class="{ 'payment-active': site.paymentMethod === 'bkash' }"
        >
          bKash
        </div>
        <div
          class="payment-method"
          @click="site.paymentMethod = 'nagad'"
          :class="{ 'payment-active': site.paymentMethod === 'nagad' }"
        >
          Nagad
        </div>
        <div
          class="payment-method"
          @click="site.paymentMethod = 'rocket'"
          :class="{ 'payment-active': site.paymentMethod === 'rocket' }"
        >
          Rocket
        </div>
        <div
          class="payment-method"
          @click="site.paymentMethod = 'cards'"
          :class="{ 'payment-active': site.paymentMethod === 'cards' }"
        >
          Card and Others
        </div>
      </div>
    </div>
    <div style="margin-top: 56px; margin-bottom: 72px">
      <Button
        :class="{ 'disable-click': site.working }"
        classes="primary-btn create-btn"
        name="Create"
        :show-done-sign="site.done"
        :show-loader="site.working"
        :with-loader="true"
        height="40px"
        padding="0 1.6rem"
        fontSize="16px"
        @click.native="checkPhoneNumber"
      ></Button>
    </div>

    <Modal v-if="phoneNumber.showModal" @close="phoneNumber.showModal = false">
      <template slot="header">
        <h6>Phone number</h6>
      </template>
      <div>
        <div class="input">
          <label for="phone-number">Enter your phone number</label>
          <input
            type="number"
            id="phone-number"
            v-model="phoneNumber.phoneNumber"
            placeholder="01XXXXXXXXX"
          />
          <p v-if="$v.phoneNumber.phoneNumber.$error" class="input-error">
            Please enter a valid phone number
          </p>
        </div>
      </div>
      <template slot="footer">
        <Button
          :class="{ 'disable-click': phoneNumber.working }"
          classes="primary-btn"
          name="Save"
          :show-done-sign="phoneNumber.done"
          :show-loader="phoneNumber.working"
          :with-loader="true"
          height="40px"
          padding="0 1.6rem"
          fontSize="16px"
          @click.native="updatePhoneNumber"
        ></Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from "@/axios";
import Button from "@/components/Button/Button";
import Package from "@/components/Package";
import FullScreenLoader from "@/components/FullScreenLoader";
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import { required } from "vuelidate/lib/validators";

const phoneNumberValidator = (value) => {
  if (value) {
    return /(^([+][8]{2}|0088)?(01)[3-9]\d{8})$/.test(value);
  }
  return false;
};

export default {
  name: "NewWebsite",
  components: {
    Button,
    Package,
    FullScreenLoader,
    Modal,
  },
  data() {
    return {
      site: {
        name: "",
        package: "",
        working: false,
        done: false,
        billType: "m",
        paymentMethod: "bkash",
      },
      packages: [],
      showLoader: true,
      phoneNumber: {
        showModal: false,
        working: false,
        done: false,
        phoneNumber: "",
      },
    };
  },
  computed: {
    ...mapGetters({ user: "GET_USER" }),
  },
  methods: {
    async getPackages() {
      try {
        const res = await axios.get(`${process.env.VUE_APP_API_URL}/package`);
        this.packages = res.data.packages;
        this.site.package = this.packages[0]._id;
      } catch (err) {
        this.$toasted.show("Something went wrong");
      }
    },
    checkPhoneNumber() {
      if (!this.user.phoneNumber) this.phoneNumber.showModal = true;
      else this.create();
    },
    async updatePhoneNumber() {
      if (!this.phoneNumber.working) {
        this.phoneNumber.working = true;
        this.$v.phoneNumber.$touch();
        if (this.$v.phoneNumber.$error) {
          this.phoneNumber.working = false;
          return false;
        }
        try {
          await axios.put(`${process.env.VUE_APP_API_URL}/user/phone-number`, {
            phoneNumber: this.phoneNumber.phoneNumber.toString(),
          });
          this.phoneNumber.showModal = false;
          await this.create();
        } catch (err) {
          this.$toasted.error("Something went wrong");
        }
        this.phoneNumber.working = false;
      }
    },
    async create() {
      if (!this.site.working) {
        this.site.working = true;
        const billType = this.site.billType;
        const pack = this.packages.find((p) => p._id === this.site.package);
        try {
          const res = await axios.post(
            `${process.env.VUE_APP_API_URL}/payment/invoice`,
            {
              packageId: this.site.package,
              duration: billType === "m" ? 30 : 365,
              amount:
                billType === "m"
                  ? pack.firstMonthPrice
                  : pack.regularPrice * 12 - pack.regularPrice * 12 * 0.2,
              websiteNickName: this.site.name,
            }
          );
          const gatewayUrl = res.data.gatewayUrl;
          if (this.site.paymentMethod === "bkash") {
            window.location = gatewayUrl.bkash;
          } else if (this.site.paymentMethod === "nagad") {
            window.location = gatewayUrl.nagad;
          } else if (this.site.paymentMethod === "rocket") {
            window.location = gatewayUrl.rocket;
          } else {
            window.location = gatewayUrl.default;
          }
        } catch (err) {
          this.$toasted.error("Something went wrong");
        }
      }
    },
    initFreshChat() {
      window.fcWidget.init({
        token: "ecc1c766-cbae-4ea0-922c-5cf6161ea37b",
        host: "https://wchat.freshchat.com",
        siteId: "AURIZOR",
        externalId: this.user._id,
        restoreId: this.user.freshChatRestoreId
          ? this.user.freshChatRestoreId
          : null,
        config: {
          headerProperty: {
            hideChatButton: false,
          },
        },
      });
      window.fcWidget.user.get((resp) => {
        const status = resp && resp.status;
        // const data = resp && resp.data
        if (status !== 200) {
          window.fcWidget.user.setProperties({
            firstName: this.user.displayName,
            email: this.user.email,
            phone: this.user.phoneNumber,
            phoneCountryCode: `+880`,
          });
          window.fcWidget.on("user:created", async (resp) => {
            const status = resp && resp.status;
            const data = resp && resp.data;
            if (status === 200) {
              if (data.restoreId) {
                await axios.post(
                  `${process.env.VUE_APP_API_URL}/user/freshchat-restore-id`,
                  {
                    restoreId: data.restoreId,
                  }
                );
              }
            }
          });
        }
      });
    },
    setInfoFromURL() {
      const { name, packageId, billType } = this.$route.query;
      if (name) {
        this.site.name = name;
        this.$toasted.error("Payment failed");
      }
      if (packageId) this.site.package = packageId;
      if (billType) this.site.billType = billType;
    },
  },
  async created() {
    setTimeout(() => {
      this.$refs.siteName.focus();
    }, 500);
    await this.getPackages();
    this.setInfoFromURL();
    setTimeout(() => {
      this.showLoader = false;
    }, 200);
    this.initFreshChat();
  },
  validations: {
    site: {
      name: { required },
    },
    phoneNumber: {
      phoneNumber: { required, phoneNumberValidator },
    },
  },
};
</script>

<style lang="scss">
.new-website {
  padding: 56px 0;

  .site-name {
    margin-top: 40px;
  }

  .create-btn {
    padding: 0 32px !important;
    height: 56px !important;
  }

  .packages-container {
    margin-top: 56px;

    .bill-active {
      background: #fff;
      box-shadow: 0 6px 8px 0 rgb(0 0 0 / 5%);
    }

    .bill-type {
      display: flex;
      width: 285px;
      padding: 8px 0;
      justify-content: space-evenly;
      background: #f3f3f3;
      border-radius: 4px;
      margin-bottom: 16px;

      .bill-type-text {
        padding: 8px 24px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        border-radius: 4px;
        transition: all 0.3s ease;
      }
    }

    .select-plan {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .packages {
      display: flex;
      margin: 0 -16px;
    }
  }

  .payment-methods {
    display: flex;
    margin: 0 -8px;

    .payment-active {
      border-color: var(--aurizor-blue) !important;
      box-shadow: 0 8px 15px 0 rgb(0 0 0 / 7%);
    }

    .payment-method {
      margin: 0 8px;
      font-size: 16px;
      padding: 10px 24px;
      border: 2px solid var(--border);
      border-radius: 4px;
      transition: all 0.3s ease;
      cursor: pointer;
    }
  }
}
</style>
