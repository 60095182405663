<template>
  <div class="package" :class="{ 'active-pack': active }">
    <h5 class="pack-name">{{ pack.name }}</h5>
    <p v-if="billType === 'm'" class="price">৳{{ price }}</p>
    <p v-else class="price">৳{{ yearlyDiscountedPrice }}<span class="price-strike">৳{{ price }}</span></p>
    <p v-if="billType === 'm'" class="first-month-price">First month ৳{{ pack.firstMonthPrice }}</p>
    <p v-else class="first-month-price">Save 20% on first year</p>
    <div class="features">
      <ul>
        <li>{{ pack.storage / 1024 }} GB of SSD Storage</li>
        <li>Unlimited Bandwidth</li>
        <li>Managed WordPress</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Package",
  props: {
    pack: Object,
    active: Boolean,
    billType: String,
  },
  computed: {
    price() {
      if (this.billType === 'y') return this.pack.regularPrice * 12;
      return this.pack.regularPrice;
    },
    yearlyDiscountedPrice() {
      return (this.pack.regularPrice * 12 - (this.pack.regularPrice * 12 * 0.2));
    }
  },
}
</script>

<style lang="scss" scoped>
.active-pack {
  border-color: var(--aurizor-blue) !important;
  box-shadow: 0 8px 15px 0 rgb(0 0 0 / 7%);
}

.package {
  margin: 0 16px;
  padding: 48px 24px;
  border: 2px solid var(--border);
  border-radius: 4px;
  cursor: pointer;
  width: 280px;
  transition: all 0.3s ease;

  .pack-name {
    font-size: 2rem;
    font-weight: 600;
  }

  .price {
    margin-top: 8px;
    font-size: 30px;
  }

  .first-month-price {
    font-size: 16px;
    margin-top: 2px;
    font-weight: 600;
    color: var(--aurizor-blue);
  }

  .price-strike {
    font-size: 18px;
    text-decoration: line-through;
    font-weight: 500;
    color: #9a9a9a;
    margin-left: 8px;
  }

  .features {
    margin-top: 24px;
    ul {
      list-style: none;
      position: relative;
      padding: 8px 0 0;
      //margin-left: -5px;

      li {
        font-size: 16px;
        margin-bottom: 10px;
        //margin-left: 8px;
      }

      //li:before {
      //  background: transparent url("../assets/img/icon/check.svg") scroll 0 0 no-repeat;
      //  content:"";
      //  width: 16px;
      //  height: 16px;
      //  position: absolute;
      //  left: 0;
      //}
    }
  }
}
</style>